import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  EditModule,
  Module, ModuleCategoryGroup, ModuleLight, ModulePreview,
  ModulePreviewResult,
  ModuleType,
  ModulesWithFilter,
  QuestionModuleData,
  QuestionModuleItem,
  QuestionModuleLogic,
  QuestionModuleRequestItem,
  UpdateModuleItems,
  UpdateModuleRules
} from 'app/shared/models';
import { ModuleTypeEnum, ModuleTypeEnumValues } from 'app/shared/models/module.model';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { AppState } from 'app/state/app.state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {


  constructor(private _http: HttpClient, private _config: ConfigurationService, private store: Store<AppState>) {
  }
  getByCompanyLight(companyId: number): Observable<ModuleLight[]> {
    return this._http.get<ModuleLight[]>(`${this._config.apiUrl}/api/companies/${companyId}/modules/light`);
  }
  getByKeysAndTags(companyId: number, keys: string[], tags: string[]): Observable<ModulesWithFilter> {
    return this._http.post<ModulesWithFilter>(`${this._config.apiUrl}/api/modules/ByKeysAndTags?companyId=${companyId}`, { keys, tags })
      .pipe(map(m => ({
        filters: m.filters,
        modules: m.modules.map(module =>
        ({
          ...module,
          imageUrl: module.meta.find(mm => mm.metaKey === 'imageUrl')?.value,
          icon: module.meta.find(mm => mm.metaKey === 'icon')?.value
        } satisfies Module))
      } satisfies ModulesWithFilter)));
  }


  get(id: number): Observable<Module> {
    return this._http.get<Module>(`${this._config.apiUrl}/api/modules/${id}`);
  }
  getExport(id: number): Observable<any> {
    return this._http.get<any>(`${this._config.apiUrl}/api/modules/${id}/export`);
  }
  getByIds(ids: number[]): Observable<Module[]> {
    let params = new HttpParams();
    for (const id of ids) {
      params = params.append('id', id.toString());
    }
    return this._http.get<Module[]>(`${this._config.apiUrl}/api/modules/`, { params: params });
  }
  create(model: EditModule): Observable<number> {
    return this._http
      .post<number>(`${this._config.apiUrl}/api/modules`, model);
  }
  update(model: EditModule): Observable<HttpResponse<Object>> {
    return this._http
      .put(`${this._config.apiUrl}/api/modules/${model.id}`, model, { observe: 'response', responseType: 'text' });

  }
  delete(id: number): Observable<void> {
    return this._http.delete<void>(`${this._config.apiUrl}/api/modules/${id}`);
  }
  setEnabledFlag(id: number, enabled: boolean, companyId: number): Observable<HttpResponse<Object>> {
    return this._http
      .put(`${this._config.apiUrl}/api/modules/${id}/enabled`, { enabled: enabled, companyId: companyId },
        { observe: 'response', responseType: 'text' });
  }
  updateModuleItems(model: UpdateModuleItems): Observable<HttpResponse<Object>> {
    return this._http
      .put(`${this._config.apiUrl}/api/modules/${model.moduleId}/items`, model, { observe: 'response', responseType: 'text' });
  }
  updateModuleRules(model: UpdateModuleRules): Observable<HttpResponse<Object>> {
    return this._http
      .put(`${this._config.apiUrl}/api/modules/${model.moduleId}/rules`, model, { observe: 'response', responseType: 'text' });
  }
  preview(model: ModulePreview): Observable<ModulePreviewResult> {
    return this._http
      .put<ModulePreviewResult>(`${this._config.apiUrl}/api/modules/${model.moduleId}/items/preview`, model);
  }
  moduleTypes(): Observable<ModuleType[]> {
    return this._http.get<ModuleType[]>(`${this._config.apiUrl}/api/moduletypes`);
  }
  copy(companyId: number, libraryId: number, moduleId: number): Observable<number> {
    return this._http.post<number>(
      `${this._config.apiUrl}/api/companies/${companyId}/libraries/${libraryId}/modules/${moduleId}/copy`, {}
    );
  }
  getByKey(key: string): Observable<Module> {
    return this._http.get<Module>(`${this._config.apiUrl}/api/moduleKey/${key}`);
  }
  getModuleCategories(modules: Module[]): ModuleCategoryGroup[] {
    const moduleCategories: ModuleCategoryGroup[] = [];
    modules.removeEvery(m => !m.enabled);
    for (const m of modules) {
      this.setIcon(m);
      this.setOrder(m);

      const categoryKey = m.category ? m.category.key : undefined;
      let categoryGroup: ModuleCategoryGroup = moduleCategories.find(c => c.category.key === categoryKey);
      if (!categoryGroup) {
        categoryGroup = {
          category: categoryKey ? m.category : { key: null, order: 1000 },
          modules: []
        };
        moduleCategories.push(categoryGroup);
      }
      if (!categoryGroup.modules.some(x => x.id === m.id)) {
        categoryGroup.modules.push({ ...m });
        categoryGroup.modules.sort((a, b) => a.order - b.order);
      }
    }
    moduleCategories.sort((a, b) => a.category.order - b.category.order);
    return moduleCategories;
  }
  getMainModule(main: string, moduleCategories: ModuleCategoryGroup[]): Module {
    let mainModule = moduleCategories.flatMap(c => c.modules).find(m => m.key === main);
    if (!mainModule && main === 'first') {
      mainModule = moduleCategories.flatMap(c => c.modules)[0];
    }
    if (mainModule) {
      mainModule.main = true;
    }
    return mainModule;
  }

  getSelectedModules(preselected: string[], moduleCategories: ModuleCategoryGroup[]): Module[] {
    const allModules = moduleCategories.flatMap(c => c.modules);
    return preselected.map(moduleKey =>
      moduleKey === 'first' ? allModules[0] : allModules.find(m => m.key === moduleKey)
    ).filter(module => !!module);

  }

  private setIcon(module: Module) {
    const icon = module.meta.filter(x => x.type === 'Icon');
    module.icon = icon.length > 0 ? icon[0].value : '';
  }
  private setOrder(module: Module) {
    const order = module.meta.filter(x => x.metaKey === 'order');
    module.order = order.length > 0 ? parseInt(order[0].value, 10) : 9999;
  }

  /* API v2 */

  getQuestionModules(moduleType: ModuleTypeEnumValues | number = ModuleTypeEnum.question): Observable<QuestionModuleData> {
    const params = new HttpParams().set('type', +moduleType);
    return this._http.get<QuestionModuleData>(`${this._config.apiUrl}/api/modules/questionmodules`,
      { params: params });
  }
  getQuestionModulesForPicker(moduleType: ModuleTypeEnumValues | number = ModuleTypeEnum.question, productDefinitionId?: number): Observable<QuestionModuleData> {
    let params = new HttpParams().set('type', moduleType.toString());
    if (productDefinitionId) {
      params = params.set('productDefinitionId', productDefinitionId.toString());
    }
    return this._http.get<QuestionModuleData>(`${this._config.apiUrl}/api/modules/pickermodules`,
      { params: params });
  }
  getForEdit(moduleId?: number): Observable<QuestionModuleItem> {
    return this._http.get<QuestionModuleItem>(`${this._config.apiUrl}/api/modules/questionmodule/${moduleId}`);
  }

  upsert(module: QuestionModuleRequestItem): Observable<{ id: number } | QuestionModuleItem> {
    return module.id ? this.UpdateQuestionModule(module) : this.CreateQuestionModule(module);
  }

  CreateQuestionModule(model: QuestionModuleRequestItem): Observable<{ id: number }> {
    return this._http
      .post<{ id: number }>(`${this._config.apiUrl}/api/modules/questionmodule`, model);
  }

  UpdateQuestionModule(model: QuestionModuleRequestItem): Observable<QuestionModuleItem> {
    return this._http
      .put<QuestionModuleItem>(`${this._config.apiUrl}/api/modules/questionmodule`, model);
  }

  getLogic(moduleId: number): Observable<QuestionModuleLogic> {
    return this._http.get<QuestionModuleLogic>(`${this._config.apiUrl}/api/modules/${moduleId}/logic`);
  }
}


